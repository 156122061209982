<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>{{ param.headerTitle }}</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">
            <v-form ref="form" v-model="valid" lazy-validation>
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="130px">
                        <col>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label class="is-required">링크명</label></th>
                            <td colspan="3">
                                <v-text-field class="jh-form is-md" name="NEW_BTN_NM" v-model="NEW_BTN_NM" maxlength="20" :rules="validateRules.newBtnNm" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">메시지내용</label></th>
                            <td colspan="3">
                                <v-textarea class="jh-form" v-model="NEW_MSG_CONTENT" min="500" :rules="validateRules.newMsgContent" outlined required></v-textarea>
                            </td>
                        </tr>                        
                        <tr>
                            <th><label class="is-required">버튼 URL(PC)</label></th>
                            <td colspan="3">
                                <v-text-field class="jh-form is-md" name="NEW_URL_PC" v-model="NEW_URL_PC" maxlength="1000" :rules="validateRules.newUrlPC" outlined required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">버튼 URL(MOBILE)</label></th>
                            <td colspan="3">
                                <v-text-field class="jh-form is-md" name="NEW_URL_MOBILE" v-model="NEW_URL_MOBILE" maxlength="1000" :rules="validateRules.newUrlMobile" outlined required></v-text-field>
                            </td>
                        </tr>                        
                        <tr>
                            <th><label class="is-required">사용여부</label></th>
                            <td>
                                <v-select  
                                    class="jh-form is-md"
                                    :items="mixin_common_code_get(this.common_code, 'TWB001','선택')" 
                                    v-model="NEW_USE_YN"
                                    :rules="validateRules.newUseYn"
                                    outlined required
                                ></v-select>
                            </td>
                            <th><label class="is-required">등록자</label></th>
                            <td>
                                <v-text-field class="jh-form is-md is-trns" name="userId" v-model="REG_ID" maxlength="20" outlined required disabled></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">정렬순서</label></th>
                            <td>
                                <v-text-field type="number" class="jh-form is-md" name="NEW_SORT_ORD" id="sortOrd" min="0" max="9999" v-model.number.lazy="NEW_SORT_ORD" :rules="validateRules.newSortOrd" outlined required></v-text-field>
                            </td>
                            <th><label></label></th>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <!-- 키워드 -->
                <div class="is-mt-10" v-show="showKeywordType">
                    <div class="jh-ui-header">
                        <h3>키워드</h3>
                    </div>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="100px">
                            <col>
                            <col width="100px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label class="is-required">순번</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" id="id" value="1" disabled></v-text-field>
                                </td>
                                <th><label class="is-required">정렬순서</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" id="id" v-model="SORT_ORD" :rules="validateRules.SORT_ORD" outlined required></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">버튼명</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" id="id" v-model="BTN_NM" outlined required></v-text-field>
                                </td>
                                <th><label class="is-required">사용여부</label></th>
                                <td>
                                    <v-select  
                                        class="jh-form is-md"
                                        :items="dropYnItems" 
                                        v-model="LINK_USE_YN"
                                        :rules="validateRules.useYn"
                                        outlined required
                                    ></v-select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </v-form>
        </div>
        <div class="jh-dialog-footer" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">
            <v-btn class="jh-btn is-md is-main" @click="submitGrid01Pop">저장</v-btn>
        </div>
    </div>
</template>
  <script>
  import api from "../../store/apiUtil";
  import {mixin} from "../../mixin/mixin";
  
  export default {
    name: "MENU_E020604P01", //name은 'MENU_' + 파일명 조합
    props: {
      param: {
        type: Object,
      },
    },
    mixins:[mixin],
    data(){
      return {
        common_code: [],
        REG_ID : this.$store.getters['userStore/GE_USER_NAME'],
        menu1: false,
        menu2: false,
        showLinkType: false,
        showKeywordType: false,
        showWeblinkType: false,
        date: {},
        date2: {},
        footerHideTitle: "닫기",
        dropYnItems: [
          { text: "선택", value:"" },
          { text: "사용", value:"Y" },
          { text: "미사용", value:"N" },
        ],
        is: {
          textMsgDesc: false,//메시지제목
          textMsgContent: false,//메시지내용
          textUseYn: false,
          textRegdName: true,//등록자TEXT_UPD_ID
          textMsgTime: false,
        },
        //valid관련 데이터
        valid        : true,
        validateRules: {
          msgDesc: [
            v => !!v || '메시지명 은(는) 필수입력입니다.',
            // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
          ],
          msgContent       : [
            v => !!v || '시스템메시지 은(는) 필수입력입니다.',
            // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
          ],
          SORT_ORD :[
            v => /[0-9]/.test(v) || '숫자만 입력 가능합니다.',
            v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
          ],
          BTN_NM :[
            v => !!v || '시스템메시지 은(는) 필수입력입니다.',
          ],
          useYn : [
            v => !!v || '사용여부은(는) 필수입력입니다.',
          ],
          URL_PC:[
            v => !!v || 'URL_PC(는) 필수입력입니다.',
            v => /^(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/.test(v) || '형식이 맞지 않습니다.'
          ],
          URL_MOBILE:[
            v => !!v || 'URL_MOBILE(는) 필수입력입니다.',
            v => /^(http(s)?:\/\/|www.)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/.test(v) || '형식이 맞지 않습니다.'
          ],
        },
        //v-model
        MSG_DESC : '',
        MSG_CONTENT: '',
        MSG_TIME : 0,
        USE_YN : '',
        LINK_USE_YN : '',
        EXTRA :'',
        BTN_NM : '',
        SORT_ORD : 0,
        URL_MOBILE : '',
        URL_PC: '',
        SYSTEM_MSG_LINKS_ID : '',

        NEW_BTN_NM: "",
        NEW_MSG_CONTENT: "",
        NEW_URL_PC: "",
        NEW_URL_MOBILE: "",
        NEW_USE_YN: "",
        REG_ID: "",
        NEW_SORT_ORD: "",
        
      }
    },
    methods:{
      async init(){
        let type = this.param.type;
        let codeName = ["TALK017","TWB001"];
        this.common_code= await this.mixin_common_code_get_all(codeName);

        if(type == "R"){//상세
          this.is = {
            textMsgDesc: false,
            textMsgContent: false,
            textUseYn: false,
            textRegdName: false,
            textMsgTime: false,
            textUpdId:false,
            strDivId:false,
            textUpdName:false,
            textMsgTimeOrg:false,
          };
        }
        this.USE_YN = this.param.data.USE_YN;
        this.MSG_DESC = this.param.data.MSG_DESC;
        this.MSG_CONTENT = this.restoreXSS(this.param.data.MSG_CONTENT);
        this.MSG_TIME = this.param.data.MSG_TIME == '' ? 0 : this.param.data.MSG_TIME;

        console.log("init(Params)::");
        console.log(this.param);
        this.setForm(this.param.data);
      },
      setForm(data){
        console.log("setForm::");
        console.log(data);
        this.NEW_BTN_NM = data.BTN_NM;
        this.NEW_MSG_CONTENT = data.MSG_CONTENT;
        this.NEW_URL_PC = data.URL_PC;
        this.NEW_URL_MOBILE = data.URL_MOBILE;
        this.NEW_USE_YN = data.USE_YN;
        this.REG_ID = data.UPD_ID;
        this.NEW_SORT_ORD = data.SORT_ORD;

      },
      validate () {
        return this.$refs.form.validate();
      },
      reset () {
        this.$refs.form.reset();
      },
      resetValidation () {
        this.$refs.form.resetValidation();
      },
      submitGrid01Pop(){
        this.submitLi();
      },
      async submitLi(){
        //validation체크
        console.log("before request");
        let requestData = {
          headers : {},
          sendData: {}
        };

        requestData.headers["URL"]     = "/api/chat/setting/untactUrl/modify";
        requestData.headers["SERVICE"] = "chat.setting.untactUrl";
        requestData.headers["METHOD"]  = "modify";
        requestData.headers["ASYNC"]   = "false";
        requestData.headers["TYPE"]    = "BIZ_SERVICE";
        requestData.headers["DATA_OBJECT"] = "LINKDATA";
  
        let msg = this.restoreXSS_Dec2(this.NEW_MSG_CONTENT);
        requestData.sendData["NEW_MSG_CONTENT"] = msg;//내용
        requestData.sendData["NEW_UPD_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;//작성자
        requestData.sendData["NEW_UPD_DEPT_CD"] ="x";
        requestData.sendData["NEW_BTN_NM"] = this.NEW_BTN_NM;
        requestData.sendData["NEW_URL_PC"] = this.NEW_URL_PC;
        requestData.sendData["NEW_REG_NAME"] = this.$store.getters['userStore/GE_USER_ROLE'].userName;
        requestData.sendData["NEW_USE_YN"] = this.NEW_USE_YN;
        requestData.sendData["NEW_URL_MOBILE"] = this.NEW_URL_MOBILE;
        requestData.sendData["NEW_SORT_ORD"] = this.NEW_SORT_ORD;
        requestData.sendData["ASP_NEWCUST_KEY"] = this.param.ASP_NEWCUST_KEY;
        requestData.sendData["UNTACT_ID"] = this.param.data.UNTACT_ID;
  
        let response = await this.common_postCall(requestData);
        this.submitLiCallBack(response);
      },
      submitLiCallBack(response){
        if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG) {
          this.common_alert(`정상적으로 수정되었습니다.`, 'done');
          this.$emit("hideAndGetList");
        }else {
          this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
        }
      },

      restoreXSS_Dec2(strText) {
        //값존재유무 체크
        if (strText === "" || strText == null) {
          return strText;
        }
        strText = strText.toString();
        //문자열 길이가 4이상일 경우만 처리
        // 이부분이 왜 걸려잇는지 모르겠음...테스트 후 길이체크는 막을 예정..
        // if(strText.length <= 3){return strText;}
        //XSS이 적용되었을 경우 원래 문자로 치환하여 반환한다.
        strText = strText.replaceAll("(", "&#40;");
        strText = strText.replaceAll(")", "&#41;");
        strText = strText.replaceAll("[", "&#91;");
        strText = strText.replaceAll("]", "&#93;");
        //strText = strText.replaceAll("&", "&amp;");
        strText = strText.replaceAll('"', "&quot;");
        strText = strText.replaceAll("'", "&apos;");
        //strText = strText.replaceAll("\r", "<br>");
        strText = strText.replaceAll("\n", "<p>");
        strText = strText.replaceAll("<", "&lt;");
        strText = strText.replaceAll(">", "&gt;");
        return strText;
      },

      closeAlert(){//
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: false,
        });
      },
      async result(requestData){
        let head='';
        let dataa='';
        //결과값을 보여주는 부분 -> 실제 사용 시 dataa의 value를 사용
        //api 호출 부분
        await api.post(requestData.headers.URL,   //api url입력
            //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
            requestData.sendData
            , //api에서 사용될 data 입력
            {head: requestData.headers
            }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
            .then((response) => {              
              if(response.data.HEADER.ERROR_FLAG){
                this.common_alert(response.data.HEADER.ERROR_MSG, 'error')
                return;
              }
              if(typeof(response.data) === "string"){
                let responseData = JSON.parse(response.data.replaceAll("\n", ""));
                // string으로 넘어올 경우 에러임.
                this.$store.commit("alertStore/openAlertDialog", {
                  alertDialogToggle: true,
                  msg: responseData.HEADER.ERROR_MSG,
                  iconClass: "svg-error-lg",
                  type: "default",
                });
                return;
              }
              if(response.data.HEADER.METHOD === "modify"){
                this.submitCallBack(response.data);
              }
            }) //api 호출 성공 이후 수행
            .catch((err) => {
              alert(err);
            }); //api호출 에러 작업 수행
      },
    },
    mounted(){
      this.init();
    },
    computed: {
      initHeaders(){
        return {
          SERVICE : 'chat.setting.system-mssage-manage.text',
          METHOD : "",
          TYPE : 'BIZ_SERVICE',
        };
      },
      paramData(){
        return {
          ASP_NEWCUST_KEY : this.param.ASP_NEWCUST_KEY,
          TEXT_MSG_CONTENT: this.restoreXSS(this.param.data.MSG_CONTENT),
          TEXT_MSG_DESC: this.param.data.MSG_DESC,
          TEXT_MSG_TIME: this.param.data.MSG_TIME,
          TEXT_MSG_TIME_ORG: this.param.data.MSG_TIME,
          UNTACT_ID: this.param.data.UNTACT_ID,
          TEXT_UPD_DEPT_CODE: "x",
          TEXT_UPD_ID: this.$store.getters['userStore/GE_USER_ROLE'].userId,
          TEXT_UPD_NAME: this.$store.getters['userStore/GE_USER_ROLE'].userName,
          TEXT_USE_YN: this.param.data.USE_YN,
          strDivID: "systemMsg",
          LINKS_TYPE : this.param.data.LINKS_TYPE,
        };
      }
    },
  };
  </script>
  <style></style>  